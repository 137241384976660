import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "843fcff3-bd8f-4500-bcdc-4fee54602015"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages843fcff3Bd8f4500Bcdc4fee54602015(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages843fcff3Bd8f4500Bcdc4fee54602015`}>
      {props.children}
    </div>
  );
}

// id: "ce1d4970-09c1-4c57-b42b-f34b5b96bf13"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageCe1d497009c14c57B42bF34b5b96bf13(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageCe1d497009c14c57B42bF34b5b96bf13 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header95604adf980b44579cf27db814cbe5f2" title="About Us" {...props} />}
      {props["about-hero-1"] || <AboutHero10bfbed61141e4e1b954c9097bbd652b1 />}
      {props["body"] || <Body448e426a3c514a419f182ad4f098a50c />}
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="Footer467a28c78e5947bfB560Df734ede6df2" {...props} />}
    </div>
  );
}

// id: "29398832-0720-4409-be31-86eef3258e54"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "afd00642-0d89-4448-99a1-0d5f49314295"
export const ImageUrl2939883207204409Be3186eef3258e54 = "https://mp1md-pub.s3-us-west-2.amazonaws.com/content/omEtAozQA9rCypTr_file.svg";

// id: "448e426a-3c51-4a41-9f18-2ad4f098a50c"
// title: ""
// type: :html
// key: "body"
// parent_id: "ce1d4970-09c1-4c57-b42b-f34b5b96bf13"
export function Body448e426a3c514a419f182ad4f098a50c(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} Body448e426a3c514a419f182ad4f098a50c`}>
      {props["about-marketing-cards"] || <MarketingCards className="AboutMarketingCards6678403e0fb0457aAdb0F7e4451b4192" parentTag={buildClassName("AboutMarketingCards6678403e0fb0457aAdb0F7e4451b4192", parentTag)} postSlug="about-us-posts" imageAs="background" {...props} />}
    </main>
  );
}

// id: "cb348496-f26f-45c4-9465-ee07a6adab6d"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterCb348496F26f45c49465Ee07a6adab6d(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterCb348496F26f45c49465Ee07a6adab6d" {...props} />
  );
}

// id: "65cb3403-7ca4-43d4-a0e5-ed660cb73b3a"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title65cb34037ca443d4A0e5Ed660cb73b3a = "Leveling";

// id: "118c9582-a5b6-4195-ac6e-39cd0bb77f4a"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "4c2805f8-435e-4532-b853-e20d52f5d567"
export function MarketingCards118c9582A5b64195Ac6e39cd0bb77f4a(props: any) {
  return (
    <MarketingCards8e5b1f1e7959411e8c4e6c39976fb1e8 parentTag="MarketingCards118c9582A5b64195Ac6e39cd0bb77f4a" postSlug="home-page-posts2" {...props} />
  );
}

// id: "7eab3233-a8e2-4613-a7e7-37eb18ed0f74"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "0163c117-f577-47ea-935d-ef2c05a17af9"
export const NavMenuSlug7eab3233A8e24613A7e737eb18ed0f74 = "marketing-primary-nav";

// id: "b414b12a-9f01-42b3-a22c-88a8e7146d88"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "5db8f368-5f48-476c-ba18-24d699c85a7e"
export function LinkedLogoB414b12a9f0142b3A22c88a8e7146d88(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogoB414b12a9f0142b3A22c88a8e7146d88" parentTag={buildClassName("LinkedLogoB414b12a9f0142b3A22c88a8e7146d88", parentTag)} url="/" label={<Label0fb2b99e97c041e291f16f8d48477a6f />} className="linked-logo" {...props} />
  );
}

// id: "0040108e-bd46-4a8e-8efa-3c61add43c37"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "03e4d8a7-5d8d-4142-a6e5-ab746925ff4f"
export const ContentSlug0040108eBd464a8e8efa3c61add43c37 = "home-hero-heading";

// id: "bbd05a28-cb9d-4439-af3b-e220fa790178"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "48691349-92c6-4e63-a56a-3211eff4990f"
export const NavMenuSlugBbd05a28Cb9d4439Af3bE220fa790178 = "members-primary-nav";

// id: "8e5b1f1e-7959-411e-8c4e-6c39976fb1e8"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards8e5b1f1e7959411e8c4e6c39976fb1e8(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards className="MarketingCards8e5b1f1e7959411e8c4e6c39976fb1e8" parentTag={buildClassName("MarketingCards8e5b1f1e7959411e8c4e6c39976fb1e8", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "8e8328db-da06-4b25-9ce1-eed55fdb1402"
// title: ""
// type: :html
// key: "header"
// parent_id: "140ace5f-4686-4837-be02-728caddb31ea"
export function Header8e8328dbDa064b259ce1Eed55fdb1402(props: any) {
  const Hero = AppStore.presets["Hero"];
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Header8e8328dbDa064b259ce1Eed55fdb1402 site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTopEf8e8c795f2e4072B865Bb9ffe3e309f" {...props} />}
      </div>
      {props["hero"] || <HeroE60e05fd3861462eB2cf02778b9adda2 />}
    </div>
  );
}

// id: "9186463f-4251-4d35-bb9b-41e0b35f5c43"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43(props: any) {
  return (
    <ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 parentTag="MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43" header-top={<DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTop3c165514D1cd4dca8e9f150e1df3388c" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "b3e55d03-3184-45db-8cf3-25aec7345d39"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesB3e55d03318445db8cf325aec7345d39(props: any) {
  return (
    <SecondaryPage9bfa4730628f48ecBf797fe6896ad240 parentTag="SignupPagesB3e55d03318445db8cf325aec7345d39" header={<SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header1fdd7ca2644d4ede949519225d4ca4e9" title="Join Us" />} {...props} />
  );
}

// id: "9550d65a-735b-4123-b09d-9e190951abbe"
// title: ""
// type: :text
// key: "title"
// parent_id: "0163c117-f577-47ea-935d-ef2c05a17af9"
export const Title9550d65a735b4123B09d9e190951abbe = null;

// id: "4444f593-f271-47ff-93b2-38db19b041c8"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "a3b8ae34-d78f-448c-8a80-f5110c31ef3f"
export function Button4444f593F27147ff93b238db19b041c8(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button className="Button4444f593F27147ff93b238db19b041c8" parentTag={buildClassName("Button4444f593F27147ff93b238db19b041c8", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "295341ed-7b23-4a6d-9ecc-98c6140f65d5"
// title: ""
// type: :text
// key: "title"
// parent_id: "1fdd7ca2-644d-4ede-9495-19225d4ca4e9"
export const Title295341ed7b234a6d9ecc98c6140f65d5 = "Join Us";

// id: "3c1fefcb-a004-4897-b1d5-c0526fee0246"
// title: ""
// type: :text
// key: "label"
// parent_id: "4444f593-f271-47ff-93b2-38db19b041c8"
export const Label3c1fefcbA0044897B1d5C0526fee0246 = "Join Us";

// id: "d637bfff-4877-4fa5-aa27-1dfa036a86d3"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderD637bfff48774fa5Aa271dfa036a86d3(props: any) {
  return (
    <MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43 parentTag="HeaderD637bfff48774fa5Aa271dfa036a86d3" {...props} />
  );
}

// id: "e212ffca-dbdd-4b66-a971-61a1a59fb277"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultE212ffcaDbdd4b66A97161a1a59fb277(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultE212ffcaDbdd4b66A97161a1a59fb277 page`}>
      {props["header"] || <MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43 parentTag="Header1225f56c0fd54d2f956927256a4a69ee" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="Footer137d041779fa4e0bB6a4Ebe5d331803e" {...props} />}
    </div>
  );
}

// id: "50691455-a740-4d66-971a-266c58f6539e"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "5f8f3dce-bef1-4396-80b7-1ffd8361f47f"
export function Hero50691455A7404d66971a266c58f6539e(props: any) {
  return (
    <HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f parentTag="Hero50691455A7404d66971a266c58f6539e" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "c62e5af9-28d3-45ff-a0bd-77dda2a0ee76"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "140ace5f-4686-4837-be02-728caddb31ea"
export function WelcomeBackC62e5af928d345ffA0bd77dda2a0ee76(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp className="WelcomeBackC62e5af928d345ffA0bd77dda2a0ee76" parentTag={buildClassName("WelcomeBackC62e5af928d345ffA0bd77dda2a0ee76", parentTag)} {...props} />
  );
}

// id: "f49ef291-4400-4be2-8120-2e45e7cc44eb"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "5db8f368-5f48-476c-ba18-24d699c85a7e"
export function NavigationF49ef29144004be281202e45e7cc44eb(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="NavigationF49ef29144004be281202e45e7cc44eb" parentTag={buildClassName("NavigationF49ef29144004be281202e45e7cc44eb", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "a6e60836-9478-4afc-96a3-b07e62947bbd"
// title: ""
// type: :text
// key: "title"
// parent_id: "026edf14-2662-41dc-b20e-4ce49a70f27e"
export const TitleA6e6083694784afc96a3B07e62947bbd = "Secondary Marketing Title 2";

// id: "9db850b4-6afe-400b-8274-11165212d512"
// title: ""
// type: :gf_preset
// key: "hero-content"
// parent_id: "e60e05fd-3861-462e-b2cf-02778b9adda2"
export function HeroContent9db850b46afe400b827411165212d512(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Hero className="HeroContent9db850b46afe400b827411165212d512" parentTag={buildClassName("HeroContent9db850b46afe400b827411165212d512", parentTag)} postSlug="home-hero" {...props} />
  );
}

// id: "4c2805f8-435e-4532-b853-e20d52f5d567"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody4c2805f8435e4532B853E20d52f5d567(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody4c2805f8435e4532B853E20d52f5d567 page-body`}>
      {props["marketingCards"] || <MarketingCards8e5b1f1e7959411e8c4e6c39976fb1e8 parentTag="MarketingCards118c9582A5b64195Ac6e39cd0bb77f4a" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "2e2b25c5-d361-4459-a6a7-d4cebc9886f6"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label2e2b25c5D3614459A6a7D4cebc9886f6(props: any) {
  return (
    <DefaultLogoAfd006420d89444899a10d5f49314295 parentTag="Label2e2b25c5D3614459A6a7D4cebc9886f6" {...props} />
  );
}

// id: "67ac9243-794f-4a4e-8dbf-5e54590021a0"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "207edd11-188d-4330-8487-3cb94f2196c5"
export function Footer67ac9243794f4a4e8dbf5e54590021a0(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="Footer67ac9243794f4a4e8dbf5e54590021a0" {...props} />
  );
}

// id: "f8eb4445-9797-44b6-9a4e-4ef28b245bd6"
// title: ""
// type: :reference
// key: "body"
// parent_id: "140ace5f-4686-4837-be02-728caddb31ea"
export function BodyF8eb4445979744b69a4e4ef28b245bd6(props: any) {
  return (
    <MarketingBody4c2805f8435e4532B853E20d52f5d567 parentTag="BodyF8eb4445979744b69a4e4ef28b245bd6" {...props} />
  );
}

// id: "c5c8087d-d58f-4084-819e-bfcb157aa7de"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "5eb0127c-2b6b-4eb1-a027-c2a06226fc04"
export function LinkedLogoC5c8087dD58f4084819eBfcb157aa7de(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogoC5c8087dD58f4084819eBfcb157aa7de" parentTag={buildClassName("LinkedLogoC5c8087dD58f4084819eBfcb157aa7de", parentTag)} label={<DefaultLogoAfd006420d89444899a10d5f49314295 parentTag="Label31b79489A7564b868a054783d3d87034" alt="logo" />} {...props} />
  );
}

// id: "099f85e6-af35-43db-94bf-0bb009f5a92d"
// title: ""
// type: :text
// key: "title"
// parent_id: "a0bd1e03-bff0-4911-95c8-9000478b52d0"
export const Title099f85e6Af3543db94bf0bb009f5a92d = "Public Events";

// id: "18b866f8-5767-4e71-8c47-6aaf0cfde8fa"
// title: ""
// type: :gf_preset
// key: "description"
// parent_id: "e60e05fd-3861-462e-b2cf-02778b9adda2"
export function Description18b866f857674e718c476aaf0cfde8fa(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="Description18b866f857674e718c476aaf0cfde8fa" parentTag={buildClassName("Description18b866f857674e718c476aaf0cfde8fa", parentTag)} postSlug="home-hero-description" contentSlug="home-hero-description" className="home-hero-description" {...props} />
  );
}

// id: "f69c2f28-265e-43df-8724-70a5195e7f0b"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageF69c2f28265e43df872470a5195e7f0b(props: any) {
  return (
    <SecondaryPage9bfa4730628f48ecBf797fe6896ad240 parentTag="ResourcesPageF69c2f28265e43df872470a5195e7f0b" header={<SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="HeaderD53d2e4b3ba74a99827267f842c4bb13" title="Resources" />} {...props} />
  );
}

// id: "9bfa4730-628f-48ec-bf79-7fe6896ad240"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage9bfa4730628f48ecBf797fe6896ad240(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage9bfa4730628f48ecBf797fe6896ad240 gf-page-layout`}>
      {props["header"] || <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="Header3c52aceb1f4e4b3886268c5588d96534" title="Secondary Page" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterAeed2dd7B50e4990Ab220cdaf77bccab" {...props} />}
    </div>
  );
}

// id: "3c52aceb-1f4e-4b38-8626-8c5588d96534"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9bfa4730-628f-48ec-bf79-7fe6896ad240"
export function Header3c52aceb1f4e4b3886268c5588d96534(props: any) {
  return (
    <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="Header3c52aceb1f4e4b3886268c5588d96534" title="Secondary Page" {...props} />
  );
}

// id: "ff5cbee0-f22b-4210-8029-80be074183e6"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2717fcd2-7686-4d0b-bfa2-5737127e53a0"
export function FooterFf5cbee0F22b4210802980be074183e6(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterFf5cbee0F22b4210802980be074183e6" {...props} />
  );
}

// id: "0640277f-ec61-4f06-b7e3-60c63582b54f"
// title: ""
// type: :gf_preset
// key: "description-para"
// parent_id: "11482b3a-40cc-4064-99a2-61f5a6843a65"
export function DescriptionPara0640277fEc614f06B7e360c63582b54f(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="DescriptionPara0640277fEc614f06B7e360c63582b54f" parentTag={buildClassName("DescriptionPara0640277fEc614f06B7e360c63582b54f", parentTag)} contentSlug="hero-description-para" {...props} />
  );
}

// id: "67c411eb-ef8d-410f-9b38-04fbab9bf410"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "c1c3c4ce-43d2-48a1-894d-5d2022d174f3"
export const ContentSlug67c411ebEf8d410f9b3804fbab9bf410 = "about-hero-heading";

// id: "205d85ef-01c9-4aa0-a932-f91f59964e61"
// title: "Join Us Page"
// type: :html
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage205d85ef01c94aa0A932F91f59964e61(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} JoinUsPage205d85ef01c94aa0A932F91f59964e61 gf-page-layout`} header={<SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header2f5664c4D27c4565Ba82612574d41535" title="Join Us" />}>
      {props["header"] || <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="HeaderAec2dfce621140a8B7ac6e6a516a7a5d" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterA6f93d5dA03a463585a0329b2be3b008" {...props} />}
    </div>
  );
}

// id: "7a2a51f9-54f2-485f-87b0-c276a0327ca8"
// title: "Content Slug"
// type: :text
// key: "contentSlug"
// parent_id: nil
export const ContentSlug7a2a51f954f2485f87b0C276a0327ca8 = "home-hero-heading";

// id: "2f5664c4-d27c-4565-ba82-612574d41535"
// title: ""
// type: :reference
// key: "header"
// parent_id: "205d85ef-01c9-4aa0-a932-f91f59964e61"
export function Header2f5664c4D27c4565Ba82612574d41535(props: any) {
  return (
    <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header2f5664c4D27c4565Ba82612574d41535" title="Join Us" {...props} />
  );
}

// id: "1fdd7ca2-644d-4ede-9495-19225d4ca4e9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b3e55d03-3184-45db-8cf3-25aec7345d39"
export function Header1fdd7ca2644d4ede949519225d4ca4e9(props: any) {
  return (
    <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header1fdd7ca2644d4ede949519225d4ca4e9" title="Join Us" {...props} />
  );
}

// id: "deb9aa89-5808-46c7-8f56-949094f69698"
// title: ""
// type: :text
// key: "href"
// parent_id: "4444f593-f271-47ff-93b2-38db19b041c8"
export const HrefDeb9aa89580846c78f56949094f69698 = "/join";

// id: "b4ead8ea-09cd-4fc3-817a-58b053fdd9b8"
// title: ""
// type: :gf_preset
// key: "heading"
// parent_id: "e60e05fd-3861-462e-b2cf-02778b9adda2"
export function HeadingB4ead8ea09cd4fc3817a58b053fdd9b8(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="HeadingB4ead8ea09cd4fc3817a58b053fdd9b8" parentTag={buildClassName("HeadingB4ead8ea09cd4fc3817a58b053fdd9b8", parentTag)} contentSlug="home-hero-heading" {...props} />
  );
}

// id: "fa77cb7a-1396-4ef2-b12a-59c71552b6b1"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1 hero`}>
      <h1>
        Our Story
      </h1>
      {props["about-description"] || <AboutDescription6b553888B2fe4afe92cf895863283189 />}
    </div>
  );
}

// id: "37fedb19-49e1-417b-a4b7-00c352d1290d"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote37fedb1949e1417bA4b700c352d1290d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote37fedb1949e1417bA4b700c352d1290d`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "5f8f3dce-bef1-4396-80b7-1ffd8361f47f"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader5f8f3dceBef1439680b71ffd8361f47f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader5f8f3dceBef1439680b71ffd8361f47f site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTopCceeb010Df6a4546Bd88Ed503f76cbc2" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f parentTag="Hero50691455A7404d66971a266c58f6539e" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "49d3b0f5-0d40-4f36-868c-82e00761d538"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "8e5b1f1e-7959-411e-8c4e-6c39976fb1e8"
export const PostSlug49d3b0f50d404f36868c82e00761d538 = "home-page-posts2";

// id: "d53d2e4b-3ba7-4a99-8272-67f842c4bb13"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f69c2f28-265e-43df-8724-70a5195e7f0b"
export function HeaderD53d2e4b3ba74a99827267f842c4bb13(props: any) {
  return (
    <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="HeaderD53d2e4b3ba74a99827267f842c4bb13" title="Resources" {...props} />
  );
}

// id: "2b50fa25-1a6c-4565-a80c-e125cad6c157"
// title: "Social Links"
// type: :html
// key: "social"
// parent_id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
export function Social2b50fa251a6c4565A80cE125cad6c157(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Social2b50fa251a6c4565A80cE125cad6c157 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61555136205304">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/wildfern_grove">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "0090ca57-720d-4fdb-a03f-c6b67b3c9741"
// title: ""
// type: :text
// key: "class"
// parent_id: "f49ef291-4400-4be2-8120-2e45e7cc44eb"
export const Class0090ca57720d4fdbA03fC6b67b3c9741 = "navigation";

// id: "9da913dd-ea41-4693-a5ae-79cf5012bdd2"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "6678403e-0fb0-457a-adb0-f7e4451b4192"
export const PostSlug9da913ddEa414693A5ae79cf5012bdd2 = "about-us-posts";

// id: "21189864-97b7-4c79-b73a-2b30895a636f"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages2118986497b74c79B73a2b30895a636f(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages2118986497b74c79B73a2b30895a636f gf-page-layout`}>
      {props["header"] || <MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43 parentTag="Header2323134cA092471b87e3381fcee20aeb" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation className="SecondaryNav81aa7e436b924d59A6a9A89f2ad8c626" parentTag={buildClassName("SecondaryNav81aa7e436b924d59A6a9A89f2ad8c626", parentTag)} {...props} />}
      <main className="MainContent">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterA4232b91B3874711873dD8d1d5b116de" {...props} />}
    </div>
  );
}

// id: "da43c1e0-9ba4-4cd0-80d2-49689adfb3b7"
// title: "about-hero-heading"
// type: :text
// key: "contentSlug"
// parent_id: nil
export const ContentSlugDa43c1e09ba44cd080d249689adfb3b7 = "about-hero-heading";

// id: "cceeb010-df6a-4546-bd88-ed503f76cbc2"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "5f8f3dce-bef1-4396-80b7-1ffd8361f47f"
export function HeaderTopCceeb010Df6a4546Bd88Ed503f76cbc2(props: any) {
  return (
    <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTopCceeb010Df6a4546Bd88Ed503f76cbc2" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "6b553888-b2fe-4afe-92cf-895863283189"
// title: "about-description"
// type: :html
// key: "about-description"
// parent_id: "fa77cb7a-1396-4ef2-b12a-59c71552b6b1"
export function AboutDescription6b553888B2fe4afe92cf895863283189(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutDescription6b553888B2fe4afe92cf895863283189`}>
      {props["about-hero-heading"] || <ContentSnippet className="AboutHeroHeadingB8b9ae2d7f3745599d9d4bf3a4d70b89" parentTag={buildClassName("AboutHeroHeadingB8b9ae2d7f3745599d9d4bf3a4d70b89", parentTag)} contentSlug="about-hero-heading" {...props} />}
    </div>
  );
}

// id: "a0b99c2d-e090-451c-bb26-dfedbf8a0725"
// title: "Contact"
// type: :html
// key: "contact"
// parent_id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
export function ContactA0b99c2dE090451cBb26Dfedbf8a0725(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ContactA0b99c2dE090451cBb26Dfedbf8a0725`}>
      <a className="info-item" href="https://www.google.com/maps/place/Buckley,+WA/@47.1624222,-122.0468909,14z/data=!3m1!4b1!4m6!3m5!1s0x5490e56a3ca1a827:0x1b3782310c07e03d!8m2!3d47.1631573!4d-122.0267787!16zL20vMDEwc3Yy?entry=ttu" noreferrer="" noopener="" target="_blank">
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="53.5 36.5 93 127" viewBox="53.5 36.5 93 127" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
          <defs>
          </defs>
          <g>
            <path d="M99.999 163.5l-3.25-3.895C94.986 157.487 53.5 107.468 53.5 82.916 53.5 57.323 74.359 36.5 99.999 36.5c25.644 0 46.501 20.823 46.501 46.416 0 24.551-41.483 74.571-43.252 76.688l-3.249 3.896zm0-118.56c-20.978 0-38.046 17.036-38.046 37.977 0 16.359 25.019 51.015 38.046 67.305 13.029-16.29 38.048-50.946 38.048-67.305 0-20.942-17.068-37.977-38.048-37.977z" fill="currentColor" data-color="1" />
            <path d="M99.999 101.658c-10.351 0-18.775-8.407-18.775-18.741 0-10.335 8.424-18.743 18.775-18.743 10.353 0 18.777 8.408 18.777 18.743 0 10.333-8.424 18.741-18.777 18.741zm0-29.046c-5.69 0-10.32 4.621-10.32 10.304 0 5.68 4.63 10.303 10.32 10.303 5.692 0 10.324-4.622 10.324-10.303 0-5.682-4.632-10.304-10.324-10.304z" fill="currentColor" data-color="1" />
          </g>
        </svg>
        <span>
          Buckley, Washington
        </span>
      </a>
      <a className="info-item" href="mailto:wildferngrove@gmail.com" noreferrer="" noopener="" target="_blank">
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="35 56 130 88" viewBox="35 56 130 88" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
          <g>
            <path d="M35 56v88h130V56H35zm64.879 58.87L49.15 64.499h101.455L99.879 114.87zM72.191 99.311l-28.755 30.025V70.757l28.755 28.554zm6.009 5.967l21.679 21.525 21.677-21.525 28.93 30.224H49.254L78.2 105.278zm49.364-5.967l29-28.796v59.092l-29-30.296z" fill="currentColor" data-color="1" />
          </g>
        </svg>
        <span>
          wildferngrove@gmail.com
        </span>
      </a>
    </div>
  );
}

// id: "3cc4f6ee-9817-46a9-b686-7d53f3c10a1e"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "cceeb010-df6a-4546-bd88-ed503f76cbc2"
export const WidgetId3cc4f6ee981746a9B6867d53f3c10a1e = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "d6efd3c1-d0ac-4d03-ae85-2c9eeeec74b8"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "b8b9ae2d-7f37-4559-9d9d-4bf3a4d70b89"
export const ContentSlugD6efd3c1D0ac4d03Ae852c9eeeec74b8 = "about-hero-heading";

// id: "655d3084-13bf-4575-b91e-856886ef8815"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader655d308413bf4575B91e856886ef8815(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTopB68113c9744a4259B4f18ef6159ab9cf" {...props} />}
      </div>
    </div>
  );
}

// id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterA4b6036c59024002A62eD4a7111475ff(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterA4b6036c59024002A62eD4a7111475ff footer`}>
      {props["social"] || <Social2b50fa251a6c4565A80cE125cad6c157 />}
      {props["links"] || <Navigation className="LinksF822e532C1074193B24673f34e6b8349" parentTag={buildClassName("LinksF822e532C1074193B24673f34e6b8349", parentTag)} navMenuSlug="footer-nav" {...props} />}
      {props["contact"] || <ContactA0b99c2dE090451cBb26Dfedbf8a0725 />}
      <div className="spacing">
      </div>
      {props["site-logo-name"] || <SiteLogoName5340b096B49c489bA1fb44adb84cba74 />}
      {props["copyright"] || <GroupCopyright className="CopyrightB5b5a76156474a2490851561a8ea2758" parentTag={buildClassName("CopyrightB5b5a76156474a2490851561a8ea2758", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag className="Groupflow1596af15E7aa433bBe3442fbf43a7178" parentTag={buildClassName("Groupflow1596af15E7aa433bBe3442fbf43a7178", parentTag)} {...props} />}
    </div>
  );
}

// id: "3b743293-c240-4ad5-a676-320b2875283b"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "5f8f3dce-bef1-4396-80b7-1ffd8361f47f"
export const BackgroundImage3b743293C2404ad5A676320b2875283b = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "1225f56c-0fd5-4d2f-9569-27256a4a69ee"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e212ffca-dbdd-4b66-a971-61a1a59fb277"
export function Header1225f56c0fd54d2f956927256a4a69ee(props: any) {
  return (
    <MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43 parentTag="Header1225f56c0fd54d2f956927256a4a69ee" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "31b79489-a756-4b86-8a05-4783d3d87034"
// title: ""
// type: :reference
// key: "label"
// parent_id: "c5c8087d-d58f-4084-819e-bfcb157aa7de"
export function Label31b79489A7564b868a054783d3d87034(props: any) {
  return (
    <DefaultLogoAfd006420d89444899a10d5f49314295 parentTag="Label31b79489A7564b868a054783d3d87034" {...props} />
  );
}

// id: "c94a6b91-7364-4e05-bce6-096a4fc54790"
// title: ""
// type: :text
// key: "url"
// parent_id: "b414b12a-9f01-42b3-a22c-88a8e7146d88"
export const UrlC94a6b9173644e05Bce6096a4fc54790 = "/";

// id: "e24ba86c-80f4-4ede-bd1d-6e33bf77d67b"
// title: ""
// type: :text
// key: "imageAs"
// parent_id: "6678403e-0fb0-457a-adb0-f7e4451b4192"
export const ImageAsE24ba86c80f44edeBd1d6e33bf77d67b = "background";

// id: "b11da187-1992-4b7c-88a4-46b50f526d35"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "5eb0127c-2b6b-4eb1-a027-c2a06226fc04"
export const BackgroundImageB11da18719924b7c88a446b50f526d35 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "137d0417-79fa-4e0b-b6a4-ebe5d331803e"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e212ffca-dbdd-4b66-a971-61a1a59fb277"
export function Footer137d041779fa4e0bB6a4Ebe5d331803e(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="Footer137d041779fa4e0bB6a4Ebe5d331803e" {...props} />
  );
}

// id: "b08c1172-cc67-4c57-8fac-672a0dea4b98"
// title: ""
// type: :text
// key: "alt"
// parent_id: "afd00642-0d89-4448-99a1-0d5f49314295"
export const AltB08c1172Cc674c578fac672a0dea4b98 = "logo";

// id: "5fc2290d-1bde-4b40-a8da-3e5d402388b7"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "5fe7b1a8-13d5-444c-b660-e54ab851354c"
export const PostSlug5fc2290d1bde4b40A8da3e5d402388b7 = "home-page-offerings";

// id: "ff33b6aa-e9e1-42ca-9cde-703b970d1af0"
// title: ""
// type: :gf_preset
// key: "description-heading"
// parent_id: "11482b3a-40cc-4064-99a2-61f5a6843a65"
export function DescriptionHeadingFf33b6aaE9e142ca9cde703b970d1af0(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="DescriptionHeadingFf33b6aaE9e142ca9cde703b970d1af0" parentTag={buildClassName("DescriptionHeadingFf33b6aaE9e142ca9cde703b970d1af0", parentTag)} contentSlug="hero-description-heading" {...props} />
  );
}

// id: "7741a6e6-ca4c-4cea-8bd0-9b2ebf76700a"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "0640277f-ec61-4f06-b7e3-60c63582b54f"
export const ContentSlug7741a6e6Ca4c4cea8bd09b2ebf76700a = "hero-description-para";

// id: "c1c3c4ce-43d2-48a1-894d-5d2022d174f3"
// title: ""
// type: :gf_preset
// key: "about-description"
// parent_id: "0bfbed61-141e-4e1b-954c-9097bbd652b1"
export function AboutDescriptionC1c3c4ce43d248a1894d5d2022d174f3(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="AboutDescriptionC1c3c4ce43d248a1894d5d2022d174f3" parentTag={buildClassName("AboutDescriptionC1c3c4ce43d248a1894d5d2022d174f3", parentTag)} contentSlug="about-hero-heading" {...props} />
  );
}

// id: "e6b9093a-cae9-49c0-972c-b9a0bc31d50d"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesE6b9093aCae949c0972cB9a0bc31d50d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesE6b9093aCae949c0972cB9a0bc31d50d gf-page-layout`}>
      {props["header"] || <MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43 parentTag="HeaderA5f367d679f74c56962b736228fc9b75" {...props} />}
      {props.children}
    </div>
  );
}

// id: "a4232b91-b387-4711-873d-d8d1d5b116de"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "21189864-97b7-4c79-b73a-2b30895a636f"
export function FooterA4232b91B3874711873dD8d1d5b116de(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterA4232b91B3874711873dD8d1d5b116de" {...props} />
  );
}

// id: "95604adf-980b-4457-9cf2-7db814cbe5f2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ce1d4970-09c1-4c57-b42b-f34b5b96bf13"
export function Header95604adf980b44579cf27db814cbe5f2(props: any) {
  return (
    <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header95604adf980b44579cf27db814cbe5f2" title="About Us" {...props} />
  );
}

// id: "ac9e4494-7203-49a2-b5e7-8be6a66e5b64"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "8e5b1f1e-7959-411e-8c4e-6c39976fb1e8"
export const ButtonClassAc9e4494720349a2B5e78be6a66e5b64 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "0fb2b99e-97c0-41e2-91f1-6f8d48477a6f"
// title: ""
// type: :html
// key: "label"
// parent_id: "b414b12a-9f01-42b3-a22c-88a8e7146d88"
export function Label0fb2b99e97c041e291f16f8d48477a6f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/omEtAozQA9rCypTr_file.svg" className={`${parentTag || ""} Label0fb2b99e97c041e291f16f8d48477a6f logo`} />
  );
}

// id: "72e3778b-9ab7-4c1f-b91d-6e4dc45ac37d"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "dc925127-3b6b-4eca-a860-1f1bce631402"
export const ContentSlug72e3778b9ab74c1fB91d6e4dc45ac37d = "home-hero-heading";

// id: "b53a046b-84c6-4f8d-992b-ff6fd198b2cc"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefB53a046b84c64f8d992bFf6fd198b2cc = "/";

// id: "aeed2dd7-b50e-4990-ab22-0cdaf77bccab"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9bfa4730-628f-48ec-bf79-7fe6896ad240"
export function FooterAeed2dd7B50e4990Ab220cdaf77bccab(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterAeed2dd7B50e4990Ab220cdaf77bccab" {...props} />
  );
}

// id: "a032cd5e-e664-4b6d-b30d-6c26614b1d38"
// title: ""
// type: :text
// key: "title"
// parent_id: "655d3084-13bf-4575-b91e-856886ef8815"
export const TitleA032cd5eE6644b6dB30d6c26614b1d38 = "Secondary Marketing Title 1";

// id: "a5f367d6-79f7-4c56-962b-736228fc9b75"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e6b9093a-cae9-49c0-972c-b9a0bc31d50d"
export function HeaderA5f367d679f74c56962b736228fc9b75(props: any) {
  return (
    <MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43 parentTag="HeaderA5f367d679f74c56962b736228fc9b75" {...props} />
  );
}

// id: "3d21e9a5-5735-4d24-a874-795d415cde37"
// title: ""
// type: :text
// key: "title"
// parent_id: "d53d2e4b-3ba7-4a99-8272-67f842c4bb13"
export const Title3d21e9a557354d24A874795d415cde37 = "Resources";

// id: "7474a19b-16bc-44c0-b5a6-c142ab802fcd"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "1225f56c-0fd5-4d2f-9569-27256a4a69ee"
export const NavMenuSlug7474a19b16bc44c0B5a6C142ab802fcd = "members-primary-nav";

// id: "e45b5e9a-3457-4b02-9110-87d62abf3174"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "9db850b4-6afe-400b-8274-11165212d512"
export const PostSlugE45b5e9a34574b02911087d62abf3174 = "home-hero";

// id: "0163c117-f577-47ea-935d-ef2c05a17af9"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader0163c117F57747ea935dEf2c05a17af9(props: any) {
  return (
    <ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 parentTag="DefaultHeader0163c117F57747ea935dEf2c05a17af9" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "e1141353-5851-4784-a983-fba16f5b387f"
// title: ""
// type: :html
// key: "logo"
// parent_id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
export function LogoE114135358514784A983Fba16f5b387f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoE114135358514784A983Fba16f5b387f logo`} />
  );
}

// id: "81aa7e43-6b92-4d59-a6a9-a89f2ad8c626"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "21189864-97b7-4c79-b73a-2b30895a636f"
export function SecondaryNav81aa7e436b924d59A6a9A89f2ad8c626(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation className="SecondaryNav81aa7e436b924d59A6a9A89f2ad8c626" parentTag={buildClassName("SecondaryNav81aa7e436b924d59A6a9A89f2ad8c626", parentTag)} {...props} />
  );
}

// id: "74af7f40-1300-4763-9a18-1a2dd2b28066"
// title: ""
// type: :html
// key: "title"
// parent_id: "fa77cb7a-1396-4ef2-b12a-59c71552b6b1"
export function Title74af7f40130047639a181a2dd2b28066(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>

    </>
  );
}

// id: "0310bb3a-c536-4788-b7f3-add33fd1859f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f822e532-c107-4193-b246-73f34e6b8349"
export const NavMenuSlug0310bb3aC5364788B7f3Add33fd1859f = "footer-nav";

// id: "a2c68aa0-2fc6-446f-a141-18d5a7076f4f"
// title: ""
// type: :text
// key: "class"
// parent_id: "b414b12a-9f01-42b3-a22c-88a8e7146d88"
export const ClassA2c68aa02fc6446fA14118d5a7076f4f = "linked-logo";

// id: "46649505-c52a-4b9b-8f7e-1e7a9897400b"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "18b866f8-5767-4e71-8c47-6aaf0cfde8fa"
export const PostSlug46649505C52a4b9b8f7e1e7a9897400b = "home-hero-description";

// id: "2717fcd2-7686-4d0b-bfa2-5737127e53a0"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage2717fcd276864d0bBfa25737127e53a0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage2717fcd276864d0bBfa25737127e53a0 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header6591a8bc3bcd4c02B4de670e1b56c1a8" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterFf5cbee0F22b4210802980be074183e6" {...props} />}
    </div>
  );
}

// id: "5fe7b1a8-13d5-444c-b660-e54ab851354c"
// title: "Services Clone ebTvTvYIRBhR5Aa"
// type: :gf_preset
// key: "services-posts"
// parent_id: "140ace5f-4686-4837-be02-728caddb31ea"
export function ServicesPosts5fe7b1a813d5444cB660E54ab851354c(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards className="ServicesPosts5fe7b1a813d5444cB660E54ab851354c" parentTag={buildClassName("ServicesPosts5fe7b1a813d5444cB660E54ab851354c", parentTag)} postSlug="home-page-offerings" {...props} />
  );
}

// id: "3c165514-d1cd-4dca-8e9f-150e1df3388c"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "9186463f-4251-4d35-bb9b-41e0b35f5c43"
export function HeaderTop3c165514D1cd4dca8e9f150e1df3388c(props: any) {
  return (
    <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTop3c165514D1cd4dca8e9f150e1df3388c" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "aec2dfce-6211-40a8-b7ac-6e6a516a7a5d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "205d85ef-01c9-4aa0-a932-f91f59964e61"
export function HeaderAec2dfce621140a8B7ac6e6a516a7a5d(props: any) {
  return (
    <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="HeaderAec2dfce621140a8B7ac6e6a516a7a5d" {...props} />
  );
}

// id: "e60e05fd-3861-462e-b2cf-02778b9adda2"
// title: ""
// type: :html
// key: "hero"
// parent_id: "8e8328db-da06-4b25-9ce1-eed55fdb1402"
export function HeroE60e05fd3861462eB2cf02778b9adda2(props: any) {
  const Hero = AppStore.presets["Hero"];
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} HeroE60e05fd3861462eB2cf02778b9adda2`}>
      {props["hero-content"] || <Hero className="HeroContent9db850b46afe400b827411165212d512" parentTag={buildClassName("HeroContent9db850b46afe400b827411165212d512", parentTag)} postSlug="home-hero" {...props} />}
      {props["description"] || <ContentSnippet className="Description18b866f857674e718c476aaf0cfde8fa" parentTag={buildClassName("Description18b866f857674e718c476aaf0cfde8fa", parentTag)} postSlug="home-hero-description" contentSlug="home-hero-description" className="home-hero-description" {...props} />}
    </section>
  );
}

// id: "6924a0ec-fd54-4020-8a59-47a25ac458c0"
// title: ""
// type: :text
// key: "title"
// parent_id: "3c52aceb-1f4e-4b38-8626-8c5588d96534"
export const Title6924a0ecFd5440208a5947a25ac458c0 = "Secondary Page";

// id: "e76b1312-1ff4-4ffe-b898-991f37c9836f"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "18b866f8-5767-4e71-8c47-6aaf0cfde8fa"
export const ContentSlugE76b13121ff44ffeB898991f37c9836f = "home-hero-description";

// id: "5eb0127c-2b6b-4eb1-a027-c2a06226fc04"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 header-top`}>
      {props["linked-logo"] || <Link className="LinkedLogoC5c8087dD58f4084819eBfcb157aa7de" parentTag={buildClassName("LinkedLogoC5c8087dD58f4084819eBfcb157aa7de", parentTag)} label={<DefaultLogoAfd006420d89444899a10d5f49314295 parentTag="Label31b79489A7564b868a054783d3d87034" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation className="Navigation4869134992c64e63A56a3211eff4990f" parentTag={buildClassName("Navigation4869134992c64e63A56a3211eff4990f", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
    </div>
  );
}

// id: "48691349-92c6-4e63-a56a-3211eff4990f"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "5eb0127c-2b6b-4eb1-a027-c2a06226fc04"
export function Navigation4869134992c64e63A56a3211eff4990f(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="Navigation4869134992c64e63A56a3211eff4990f" parentTag={buildClassName("Navigation4869134992c64e63A56a3211eff4990f", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "f822e532-c107-4193-b246-73f34e6b8349"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
export function LinksF822e532C1074193B24673f34e6b8349(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation className="LinksF822e532C1074193B24673f34e6b8349" parentTag={buildClassName("LinksF822e532C1074193B24673f34e6b8349", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "f688e848-6b0e-48f1-ab57-70ac43b10a13"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "140ace5f-4686-4837-be02-728caddb31ea"
export function FooterF688e8486b0e48f1Ab5770ac43b10a13(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterF688e8486b0e48f1Ab5770ac43b10a13" {...props} />
  );
}

// id: "ef8e8c79-5f2e-4072-b865-bb9ffe3e309f"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "8e8328db-da06-4b25-9ce1-eed55fdb1402"
export function HeaderTopEf8e8c795f2e4072B865Bb9ffe3e309f(props: any) {
  return (
    <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTopEf8e8c795f2e4072B865Bb9ffe3e309f" {...props} />
  );
}

// id: "ad730495-e6d1-42e1-881d-19a6c4a5423c"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "118c9582-a5b6-4195-ac6e-39cd0bb77f4a"
export const PostSlugAd730495E6d142e1881d19a6c4a5423c = "home-page-posts2";

// id: "7bf32d9c-2b75-4eac-a810-28fac926fa57"
// title: ""
// type: :text
// key: "className"
// parent_id: "18b866f8-5767-4e71-8c47-6aaf0cfde8fa"
export const ClassName7bf32d9c2b754eacA81028fac926fa57 = "home-hero-description";

// id: "207edd11-188d-4330-8487-3cb94f2196c5"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage207edd11188d433084873cb94f2196c5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage207edd11188d433084873cb94f2196c5 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="HeaderA0bd1e03Bff0491195c89000478b52d0" title="Public Events" {...props} />}
      <main>
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="Footer67ac9243794f4a4e8dbf5e54590021a0" {...props} />}
    </div>
  );
}

// id: "a0bd1e03-bff0-4911-95c8-9000478b52d0"
// title: ""
// type: :reference
// key: "header"
// parent_id: "207edd11-188d-4330-8487-3cb94f2196c5"
export function HeaderA0bd1e03Bff0491195c89000478b52d0(props: any) {
  return (
    <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="HeaderA0bd1e03Bff0491195c89000478b52d0" title="Public Events" {...props} />
  );
}

// id: "0bfbed61-141e-4e1b-954c-9097bbd652b1"
// title: ""
// type: :html
// key: "about-hero-1"
// parent_id: "ce1d4970-09c1-4c57-b42b-f34b5b96bf13"
export function AboutHero10bfbed61141e4e1b954c9097bbd652b1(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutHero10bfbed61141e4e1b954c9097bbd652b1`}>
      <h1>
        Our Story
      </h1>
      <div className="about-description">
        {props["about-description"] || <ContentSnippet className="AboutDescriptionC1c3c4ce43d248a1894d5d2022d174f3" parentTag={buildClassName("AboutDescriptionC1c3c4ce43d248a1894d5d2022d174f3", parentTag)} contentSlug="about-hero-heading" {...props} />}
      </div>
    </div>
  );
}

// id: "026edf14-2662-41dc-b20e-4ce49a70f27e"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "655d3084-13bf-4575-b91e-856886ef8815"
export function Hero026edf14266241dcB20e4ce49a70f27e(props: any) {
  return (
    <SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1 parentTag="Hero026edf14266241dcB20e4ce49a70f27e" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "6678403e-0fb0-457a-adb0-f7e4451b4192"
// title: "About Page Marketing Cards"
// type: :gf_preset
// key: "about-marketing-cards"
// parent_id: "448e426a-3c51-4a41-9f18-2ad4f098a50c"
export function AboutMarketingCards6678403e0fb0457aAdb0F7e4451b4192(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards className="AboutMarketingCards6678403e0fb0457aAdb0F7e4451b4192" parentTag={buildClassName("AboutMarketingCards6678403e0fb0457aAdb0F7e4451b4192", parentTag)} postSlug="about-us-posts" imageAs="background" {...props} />
  );
}

// id: "d8c3ff04-bea1-437f-82d4-f21a074d8e03"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "3c165514-d1cd-4dca-8e9f-150e1df3388c"
export const NavMenuSlugD8c3ff04Bea1437f82d4F21a074d8e03 = "members-primary-nav";

// id: "bc9d579b-4ac1-4a98-83c1-06912d07a8b6"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "b4ead8ea-09cd-4fc3-817a-58b053fdd9b8"
export const ContentSlugBc9d579b4ac14a9883c106912d07a8b6 = "home-hero-heading";

// id: "afd00642-0d89-4448-99a1-0d5f49314295"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoAfd006420d89444899a10d5f49314295(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/omEtAozQA9rCypTr_file.svg" className={`${parentTag || ""} DefaultLogoAfd006420d89444899a10d5f49314295 logo`} alt="logo" />
  );
}

// id: "2323134c-a092-471b-87e3-381fcee20aeb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "21189864-97b7-4c79-b73a-2b30895a636f"
export function Header2323134cA092471b87e3381fcee20aeb(props: any) {
  return (
    <MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43 parentTag="Header2323134cA092471b87e3381fcee20aeb" {...props} />
  );
}

// id: "6591a8bc-3bcd-4c02-b4de-670e1b56c1a8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2717fcd2-7686-4d0b-bfa2-5737127e53a0"
export function Header6591a8bc3bcd4c02B4de670e1b56c1a8(props: any) {
  return (
    <SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 parentTag="Header6591a8bc3bcd4c02B4de670e1b56c1a8" title="Leveling" {...props} />
  );
}

// id: "5340b096-b49c-489b-a1fb-44adb84cba74"
// title: "site-logo-name"
// type: :html
// key: "site-logo-name"
// parent_id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
export function SiteLogoName5340b096B49c489bA1fb44adb84cba74(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/s2mZXXoqbYzP1qPv_file.svg" alt="wildfern grove logo" className={`${parentTag || ""} SiteLogoName5340b096B49c489bA1fb44adb84cba74 site-name-logo`} />
  );
}

// id: "dc925127-3b6b-4eca-a860-1f1bce631402"
// title: ""
// type: :gf_preset
// key: "heading"
// parent_id: "11482b3a-40cc-4064-99a2-61f5a6843a65"
export function HeadingDc9251273b6b4ecaA8601f1bce631402(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="HeadingDc9251273b6b4ecaA8601f1bce631402" parentTag={buildClassName("HeadingDc9251273b6b4ecaA8601f1bce631402", parentTag)} contentSlug="home-hero-heading" {...props} />
  );
}

// id: "ac3c3535-59cf-4b96-8c73-fd2c6e61111f"
// title: ""
// type: :text
// key: "title"
// parent_id: "2f5664c4-d27c-4565-ba82-612574d41535"
export const TitleAc3c353559cf4b968c73Fd2c6e61111f = "Join Us";

// id: "a3b8ae34-d78f-448c-8a80-f5110c31ef3f"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f `}>
      <div className="hero-content">
        {props["heading"] || <ContentSnippet className="Heading03e4d8a75d8d4142A6e5Ab746925ff4f" parentTag={buildClassName("Heading03e4d8a75d8d4142A6e5Ab746925ff4f", parentTag)} contentSlug="home-hero-heading" {...props} />}
        <div className="hero-button-container">
          <a href="#welcome-main-section">
            <button className="hero-button">
              <span className="svg-container-hero">
                <svg className="button-svg" data-bbox="19.999 58 160.001 84" viewBox="0 0 200 200" height="28" width="28" xmlns="http://www.w3.org/2000/svg" data-type="shape">
                  <g transform="rotate(180 100 100)">
                    <path d="M172.5 142a7.485 7.485 0 0 1-5.185-2.073L100 75.808l-67.315 64.12c-2.998 2.846-7.74 2.744-10.606-.234a7.454 7.454 0 0 1 .235-10.565l72.5-69.057a7.524 7.524 0 0 1 10.371 0l72.5 69.057a7.455 7.455 0 0 1 .235 10.565A7.503 7.503 0 0 1 172.5 142z" fill="white" />
                  </g>
                </svg>
              </span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

// id: "b68113c9-744a-4259-b4f1-8ef6159ab9cf"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "655d3084-13bf-4575-b91e-856886ef8815"
export function HeaderTopB68113c9744a4259B4f18ef6159ab9cf(props: any) {
  return (
    <DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e parentTag="HeaderTopB68113c9744a4259B4f18ef6159ab9cf" {...props} />
  );
}

// id: "03e4d8a7-5d8d-4142-a6e5-ab746925ff4f"
// title: ""
// type: :gf_preset
// key: "heading"
// parent_id: "a3b8ae34-d78f-448c-8a80-f5110c31ef3f"
export function Heading03e4d8a75d8d4142A6e5Ab746925ff4f(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="Heading03e4d8a75d8d4142A6e5Ab746925ff4f" parentTag={buildClassName("Heading03e4d8a75d8d4142A6e5Ab746925ff4f", parentTag)} contentSlug="home-hero-heading" {...props} />
  );
}

// id: "b5b5a761-5647-4a24-9085-1561a8ea2758"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
export function CopyrightB5b5a76156474a2490851561a8ea2758(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright className="CopyrightB5b5a76156474a2490851561a8ea2758" parentTag={buildClassName("CopyrightB5b5a76156474a2490851561a8ea2758", parentTag)} {...props} />
  );
}

// id: "223d80f3-dc1e-47eb-aea1-80e9bebb5204"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "50691455-a740-4d66-971a-266c58f6539e"
export const WidgetId223d80f3Dc1e47ebAea180e9bebb5204 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "9251918c-dd9a-471a-8538-2065751a4fd7"
// title: ""
// type: :text
// key: "title"
// parent_id: "6591a8bc-3bcd-4c02-b4de-670e1b56c1a8"
export const Title9251918cDd9a471a85382065751a4fd7 = "Leveling";

// id: "5db8f368-5f48-476c-ba18-24d699c85a7e"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e header-top`}>
      {props["linked-logo"] || <Link className="LinkedLogoB414b12a9f0142b3A22c88a8e7146d88" parentTag={buildClassName("LinkedLogoB414b12a9f0142b3A22c88a8e7146d88", parentTag)} url="/" label={<Label0fb2b99e97c041e291f16f8d48477a6f />} className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation className="NavigationF49ef29144004be281202e45e7cc44eb" parentTag={buildClassName("NavigationF49ef29144004be281202e45e7cc44eb", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "11482b3a-40cc-4064-99a2-61f5a6843a65"
// title: "hero-home-2"
// type: :html
// key: "hero-home-2"
// parent_id: "5f8f3dce-bef1-4396-80b7-1ffd8361f47f"
export function HeroHome211482b3a40cc406499a261f5a6843a65(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} HeroHome211482b3a40cc406499a261f5a6843a65 `}>
      <div className="hero-content">
        <div className="homepage-forest-backdrop">
          <div className="homepage-sanctuary-title">
            <div className="hero-title-innerContainer">
              {props["heading"] || <ContentSnippet className="HeadingDc9251273b6b4ecaA8601f1bce631402" parentTag={buildClassName("HeadingDc9251273b6b4ecaA8601f1bce631402", parentTag)} contentSlug="home-hero-heading" {...props} />}
            </div>
            <div className="homepage-action-buttons">
              <button className="homepage-button homepage-reserve-button">
                Reserve Your Yurt
              </button>
              <button className="homepage-button homepage-host-button">
                Host Your Private Event
              </button>
            </div>
          </div>
          <div className="homepage-main-content">
            <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/y560e3L1XPuas_r-_file.svg" alt="Yurt in Forest" className="homepage-yurt-image" />
          </div>
        </div>
        <div className="hero-banner-description-container">
          <div className="hero-banner-description-Innercontainer">
            <div className="description-heading-container">
              <h1 className="description-heading">
                {props["description-heading"] || <ContentSnippet className="DescriptionHeadingFf33b6aaE9e142ca9cde703b970d1af0" parentTag={buildClassName("DescriptionHeadingFf33b6aaE9e142ca9cde703b970d1af0", parentTag)} contentSlug="hero-description-heading" {...props} />}
              </h1>
            </div>
            <div className="description-para-container">
              <p>
                {props["description-para"] || <ContentSnippet className="DescriptionPara0640277fEc614f06B7e360c63582b54f" parentTag={buildClassName("DescriptionPara0640277fEc614f06B7e360c63582b54f", parentTag)} contentSlug="hero-description-para" {...props} />}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "1596af15-e7aa-433b-be34-42fbf43a7178"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "a4b6036c-5902-4002-a62e-d4a7111475ff"
export function Groupflow1596af15E7aa433bBe3442fbf43a7178(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag className="Groupflow1596af15E7aa433bBe3442fbf43a7178" parentTag={buildClassName("Groupflow1596af15E7aa433bBe3442fbf43a7178", parentTag)} {...props} />
  );
}

// id: "a6f93d5d-a03a-4635-85a0-329b2be3b008"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "205d85ef-01c9-4aa0-a932-f91f59964e61"
export function FooterA6f93d5dA03a463585a0329b2be3b008(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterA6f93d5dA03a463585a0329b2be3b008" {...props} />
  );
}

// id: "769e83a2-13d2-42b1-b8db-54e22ed6dfea"
// title: ""
// type: :text
// key: "title"
// parent_id: "95604adf-980b-4457-9cf2-7db814cbe5f2"
export const Title769e83a213d242b1B8db54e22ed6dfea = "About Us";

// id: "bd1ca05b-d8ff-4dbd-b61d-10d2fb6255b9"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "ff33b6aa-e9e1-42ca-9cde-703b970d1af0"
export const ContentSlugBd1ca05bD8ff4dbdB61d10d2fb6255b9 = "hero-description-heading";

// id: "467a28c7-8e59-47bf-b560-df734ede6df2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ce1d4970-09c1-4c57-b42b-f34b5b96bf13"
export function Footer467a28c78e5947bfB560Df734ede6df2(props: any) {
  return (
    <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="Footer467a28c78e5947bfB560Df734ede6df2" {...props} />
  );
}

// id: "d1bc6022-3660-4a0f-b365-85eb9339215b"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f49ef291-4400-4be2-8120-2e45e7cc44eb"
export const NavMenuSlugD1bc602236604a0fB36585eb9339215b = "marketing-primary-nav";

// id: "b8b9ae2d-7f37-4559-9d9d-4bf3a4d70b89"
// title: "about hero heading"
// type: :gf_preset
// key: "about-hero-heading"
// parent_id: "6b553888-b2fe-4afe-92cf-895863283189"
export function AboutHeroHeadingB8b9ae2d7f3745599d9d4bf3a4d70b89(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet className="AboutHeroHeadingB8b9ae2d7f3745599d9d4bf3a4d70b89" parentTag={buildClassName("AboutHeroHeadingB8b9ae2d7f3745599d9d4bf3a4d70b89", parentTag)} contentSlug="about-hero-heading" {...props} />
  );
}

// id: "140ace5f-4686-4837-be02-728caddb31ea"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage140ace5f46864837Be02728caddb31ea(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage140ace5f46864837Be02728caddb31ea gf-page-layout`}>
      {props["header"] || <Header8e8328dbDa064b259ce1Eed55fdb1402 />}
      <main>
        {props["welcome-back"] || <WelcomeBackPopUp className="WelcomeBackC62e5af928d345ffA0bd77dda2a0ee76" parentTag={buildClassName("WelcomeBackC62e5af928d345ffA0bd77dda2a0ee76", parentTag)} {...props} />}
        <div className="services-title" id="choose-your-own-adventure">
          <h2>
            Choose your own adventure at Wildfern Grove
          </h2>
        </div>
        <div>
          {props["services-posts"] || <MarketingCards className="ServicesPosts5fe7b1a813d5444cB660E54ab851354c" parentTag={buildClassName("ServicesPosts5fe7b1a813d5444cB660E54ab851354c", parentTag)} postSlug="home-page-offerings" {...props} />}
        </div>
      </main>
      {props["footer"] || <DefaultFooterA4b6036c59024002A62eD4a7111475ff parentTag="FooterF688e8486b0e48f1Ab5770ac43b10a13" {...props} />}
    </div>
  );
}

const Components = {
  AdminPages843fcff3Bd8f4500Bcdc4fee54602015,
  AboutUsPageCe1d497009c14c57B42bF34b5b96bf13,
  ImageUrl2939883207204409Be3186eef3258e54,
  Body448e426a3c514a419f182ad4f098a50c,
  FooterCb348496F26f45c49465Ee07a6adab6d,
  Title65cb34037ca443d4A0e5Ed660cb73b3a,
  MarketingCards118c9582A5b64195Ac6e39cd0bb77f4a,
  NavMenuSlug7eab3233A8e24613A7e737eb18ed0f74,
  LinkedLogoB414b12a9f0142b3A22c88a8e7146d88,
  ContentSlug0040108eBd464a8e8efa3c61add43c37,
  NavMenuSlugBbd05a28Cb9d4439Af3bE220fa790178,
  MarketingCards8e5b1f1e7959411e8c4e6c39976fb1e8,
  Header8e8328dbDa064b259ce1Eed55fdb1402,
  MembersAreaHeader9186463f42514d35Bb9b41e0b35f5c43,
  SignupPagesB3e55d03318445db8cf325aec7345d39,
  Title9550d65a735b4123B09d9e190951abbe,
  Button4444f593F27147ff93b238db19b041c8,
  Title295341ed7b234a6d9ecc98c6140f65d5,
  Label3c1fefcbA0044897B1d5C0526fee0246,
  HeaderD637bfff48774fa5Aa271dfa036a86d3,
  MembersAreaDefaultE212ffcaDbdd4b66A97161a1a59fb277,
  Hero50691455A7404d66971a266c58f6539e,
  WelcomeBackC62e5af928d345ffA0bd77dda2a0ee76,
  NavigationF49ef29144004be281202e45e7cc44eb,
  TitleA6e6083694784afc96a3B07e62947bbd,
  HeroContent9db850b46afe400b827411165212d512,
  MarketingBody4c2805f8435e4532B853E20d52f5d567,
  Label2e2b25c5D3614459A6a7D4cebc9886f6,
  Footer67ac9243794f4a4e8dbf5e54590021a0,
  BodyF8eb4445979744b69a4e4ef28b245bd6,
  LinkedLogoC5c8087dD58f4084819eBfcb157aa7de,
  Title099f85e6Af3543db94bf0bb009f5a92d,
  Description18b866f857674e718c476aaf0cfde8fa,
  ResourcesPageF69c2f28265e43df872470a5195e7f0b,
  SecondaryPage9bfa4730628f48ecBf797fe6896ad240,
  Header3c52aceb1f4e4b3886268c5588d96534,
  FooterFf5cbee0F22b4210802980be074183e6,
  DescriptionPara0640277fEc614f06B7e360c63582b54f,
  ContentSlug67c411ebEf8d410f9b3804fbab9bf410,
  JoinUsPage205d85ef01c94aa0A932F91f59964e61,
  ContentSlug7a2a51f954f2485f87b0C276a0327ca8,
  Header2f5664c4D27c4565Ba82612574d41535,
  Header1fdd7ca2644d4ede949519225d4ca4e9,
  HrefDeb9aa89580846c78f56949094f69698,
  HeadingB4ead8ea09cd4fc3817a58b053fdd9b8,
  SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1,
  PageQuote37fedb1949e1417bA4b700c352d1290d,
  HomeHeader5f8f3dceBef1439680b71ffd8361f47f,
  PostSlug49d3b0f50d404f36868c82e00761d538,
  HeaderD53d2e4b3ba74a99827267f842c4bb13,
  Social2b50fa251a6c4565A80cE125cad6c157,
  Class0090ca57720d4fdbA03fC6b67b3c9741,
  PostSlug9da913ddEa414693A5ae79cf5012bdd2,
  MemberPages2118986497b74c79B73a2b30895a636f,
  ContentSlugDa43c1e09ba44cd080d249689adfb3b7,
  HeaderTopCceeb010Df6a4546Bd88Ed503f76cbc2,
  AboutDescription6b553888B2fe4afe92cf895863283189,
  ContactA0b99c2dE090451cBb26Dfedbf8a0725,
  WidgetId3cc4f6ee981746a9B6867d53f3c10a1e,
  ContentSlugD6efd3c1D0ac4d03Ae852c9eeeec74b8,
  SecondaryMarketingHeader655d308413bf4575B91e856886ef8815,
  DefaultFooterA4b6036c59024002A62eD4a7111475ff,
  BackgroundImage3b743293C2404ad5A676320b2875283b,
  Header1225f56c0fd54d2f956927256a4a69ee,
  Label31b79489A7564b868a054783d3d87034,
  UrlC94a6b9173644e05Bce6096a4fc54790,
  ImageAsE24ba86c80f44edeBd1d6e33bf77d67b,
  BackgroundImageB11da18719924b7c88a446b50f526d35,
  Footer137d041779fa4e0bB6a4Ebe5d331803e,
  AltB08c1172Cc674c578fac672a0dea4b98,
  PostSlug5fc2290d1bde4b40A8da3e5d402388b7,
  DescriptionHeadingFf33b6aaE9e142ca9cde703b970d1af0,
  ContentSlug7741a6e6Ca4c4cea8bd09b2ebf76700a,
  AboutDescriptionC1c3c4ce43d248a1894d5d2022d174f3,
  MessagePagesE6b9093aCae949c0972cB9a0bc31d50d,
  FooterA4232b91B3874711873dD8d1d5b116de,
  Header95604adf980b44579cf27db814cbe5f2,
  ButtonClassAc9e4494720349a2B5e78be6a66e5b64,
  Label0fb2b99e97c041e291f16f8d48477a6f,
  ContentSlug72e3778b9ab74c1fB91d6e4dc45ac37d,
  HrefB53a046b84c64f8d992bFf6fd198b2cc,
  FooterAeed2dd7B50e4990Ab220cdaf77bccab,
  TitleA032cd5eE6644b6dB30d6c26614b1d38,
  HeaderA5f367d679f74c56962b736228fc9b75,
  Title3d21e9a557354d24A874795d415cde37,
  NavMenuSlug7474a19b16bc44c0B5a6C142ab802fcd,
  PostSlugE45b5e9a34574b02911087d62abf3174,
  DefaultHeader0163c117F57747ea935dEf2c05a17af9,
  LogoE114135358514784A983Fba16f5b387f,
  SecondaryNav81aa7e436b924d59A6a9A89f2ad8c626,
  Title74af7f40130047639a181a2dd2b28066,
  NavMenuSlug0310bb3aC5364788B7f3Add33fd1859f,
  ClassA2c68aa02fc6446fA14118d5a7076f4f,
  PostSlug46649505C52a4b9b8f7e1e7a9897400b,
  AboutLevelingPage2717fcd276864d0bBfa25737127e53a0,
  ServicesPosts5fe7b1a813d5444cB660E54ab851354c,
  HeaderTop3c165514D1cd4dca8e9f150e1df3388c,
  HeaderAec2dfce621140a8B7ac6e6a516a7a5d,
  HeroE60e05fd3861462eB2cf02778b9adda2,
  Title6924a0ecFd5440208a5947a25ac458c0,
  ContentSlugE76b13121ff44ffeB898991f37c9836f,
  ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04,
  Navigation4869134992c64e63A56a3211eff4990f,
  LinksF822e532C1074193B24673f34e6b8349,
  FooterF688e8486b0e48f1Ab5770ac43b10a13,
  HeaderTopEf8e8c795f2e4072B865Bb9ffe3e309f,
  PostSlugAd730495E6d142e1881d19a6c4a5423c,
  ClassName7bf32d9c2b754eacA81028fac926fa57,
  PublicEventsPage207edd11188d433084873cb94f2196c5,
  HeaderA0bd1e03Bff0491195c89000478b52d0,
  AboutHero10bfbed61141e4e1b954c9097bbd652b1,
  Hero026edf14266241dcB20e4ce49a70f27e,
  AboutMarketingCards6678403e0fb0457aAdb0F7e4451b4192,
  NavMenuSlugD8c3ff04Bea1437f82d4F21a074d8e03,
  ContentSlugBc9d579b4ac14a9883c106912d07a8b6,
  DefaultLogoAfd006420d89444899a10d5f49314295,
  Header2323134cA092471b87e3381fcee20aeb,
  Header6591a8bc3bcd4c02B4de670e1b56c1a8,
  SiteLogoName5340b096B49c489bA1fb44adb84cba74,
  HeadingDc9251273b6b4ecaA8601f1bce631402,
  TitleAc3c353559cf4b968c73Fd2c6e61111f,
  HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f,
  HeaderTopB68113c9744a4259B4f18ef6159ab9cf,
  Heading03e4d8a75d8d4142A6e5Ab746925ff4f,
  CopyrightB5b5a76156474a2490851561a8ea2758,
  WidgetId223d80f3Dc1e47ebAea180e9bebb5204,
  Title9251918cDd9a471a85382065751a4fd7,
  DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e,
  HeroHome211482b3a40cc406499a261f5a6843a65,
  Groupflow1596af15E7aa433bBe3442fbf43a7178,
  FooterA6f93d5dA03a463585a0329b2be3b008,
  Title769e83a213d242b1B8db54e22ed6dfea,
  ContentSlugBd1ca05bD8ff4dbdB61d10d2fb6255b9,
  Footer467a28c78e5947bfB560Df734ede6df2,
  NavMenuSlugD1bc602236604a0fB36585eb9339215b,
  AboutHeroHeadingB8b9ae2d7f3745599d9d4bf3a4d70b89,
  HomePage140ace5f46864837Be02728caddb31ea
}

export default Components;